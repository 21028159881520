import { FronteggSignupError } from '@dtx-company/ithaca-sdk/src/frontegg/error'
import { SIGN_IN_ERROR_MESSAGE } from '@dtx-company/true-common/src/constants/auth'
import { ServerSideError, ServerSideErrorType, logger } from '@dtx-company/logger'
import { User, login } from '@dtx-company/ithaca-sdk/src'

export interface LoginProps {
  email: string
  password: string
  invitationToken?: string
}

export function useLogin(): (payload: LoginProps) => Promise<User | void> {
  const fronteggLogin = async (payload: LoginProps): Promise<User | void> => {
    try {
      const res = await login(payload)

      if (typeof res === 'object' && Array.isArray(res?.errors)) {
        logger.logError(
          new ServerSideError({
            name: 'signin',
            message: res.errors?.[0],
            endpoint: 'signin',
            type: ServerSideErrorType.ServerSideValidationError
          }),
          { technicalArea: 'authentication' }
        )
        throw new FronteggSignupError(res.errors?.[0])
      }
      if (res) {
        return { email: payload.email }
      }
    } catch (e) {
      logger.logError(e, { technicalArea: 'authentication' })
    }
  }

  return fronteggLogin
}

export const useLoginExistingUser = (): {
  loginExistingUser: (
    email: string,
    password: string
  ) => Promise<{ user?: User; errorMessage?: string }>
} => {
  const login = useLogin()
  const loginExistingUser = async (
    email: string,
    password: string
  ): Promise<{ user?: User; errorMessage?: string }> => {
    try {
      const user = await login({
        email,
        password
      })
      return {
        user: user || undefined
      }
    } catch (e) {
      let errorMessage = SIGN_IN_ERROR_MESSAGE

      if (e instanceof FronteggSignupError) {
        errorMessage = e.message
      }
      return {
        errorMessage
      }
    }
  }
  return {
    loginExistingUser
  }
}

import { FronteggSignupError } from '@dtx-company/ithaca-sdk/src/frontegg/error'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { ServerSideError, ServerSideErrorType, logger } from '@dtx-company/logger'
import { SignUpLocation, User, signupWithEmailAndPassword } from '@dtx-company/ithaca-sdk/src'
import { useCohort } from '@dtx-company/inter-app/src/hooks/cohorts/useCohort'
import { useFlowFeature } from '../useFlowFeature/useFlowFeature'
import { useRouter } from 'next/router'

export const PASSWORD_REGEX = /.*?(.)\1{2,}.*/

export type SignupProps = {
  email: string
  password: string
  product: string | null
  provider?: string
  cohortName?: string
  isEmailVerificationEnabled?: boolean
  noMarketingEmails?: boolean
}

function getSignupLocation(pathname: string): SignUpLocation {
  switch (pathname) {
    case Routes.SIGN_UP:
      return 'signUpPage'
    case Routes.FREE_QR_CODE_GENERATOR:
      return 'loggedOutCodeGenerator'
    default:
      return 'unknown'
  }
}

export function useSignup(): (props: SignupProps) => Promise<User | void> {
  const [isEmailVerificationEnabled] = useFlowFeature('email-verification')
  const skipInviteEmail = !isEmailVerificationEnabled

  const { name: cohortName } = useCohort()

  const signupLocation = getSignupLocation(useRouter().pathname)

  return async props => {
    if (PASSWORD_REGEX.test(props.password)) {
      throw new FronteggSignupError('Password cannot have 3 or more recurring characters')
    }

    let resp = await signupWithEmailAndPassword({
      ...props,
      cohort: cohortName,
      skipInviteEmail,
      signupLocation
    })

    //temporary hack to fix a race condition with Frontegg's User Created webhook (which creates the user & assigns an ithacaid)
    //finishing after the JWT generation prehook so the JWT generation prehook fails with a user not found error
    //a 2nd call to signup will return a user-already-exists,  but follow the flow of then logging the user in
    //Once the singn-in webhook is moved to a prehook, this can be removed
    if (
      typeof resp === 'object' &&
      Array.isArray(resp?.errors) &&
      resp.errors.includes('user-not-found')
    ) {
      logger.info({
        message: `User not found, retrying signup for ${props.email}`,
        name: 'signup - user-not-found',
        technicalArea: 'authentication'
      })
      resp = await signupWithEmailAndPassword({
        ...props,
        cohort: cohortName,
        skipInviteEmail,
        signupLocation
      })
    }

    if (typeof resp === 'object' && Array.isArray(resp?.errors)) {
      logger.logError(
        new ServerSideError({
          name: 'signup',
          message: resp.errors?.[0],
          endpoint: 'signup',
          type: ServerSideErrorType.ServerSideValidationError
        }),
        { technicalArea: 'authentication' }
      )
      throw new FronteggSignupError(resp.errors?.[0])
    }

    if (resp) {
      return { email: props.email }
    }
  }
}
